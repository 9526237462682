body {
    padding-top: 75px;
}
#login {
    max-width: 300px;
    margin: auto;
    margin-top: 45px;
}
.app-name {
    float: right;
    color: rgba(0, 0, 0, 0.1);
}
.alert {
    max-width: 360px;
    position: fixed;
    top: 15px;
    right: 0px;
}
